export const plans = {
  starter: {
    stripeId: {
      test: 'plan_Emyn2XD4MTvsfU',
      live: 'plan_FAF2MKh3j00Xav',
    },
    emailsLimit: 0.1,
    emailsLimitDisplayValue: '100k',
    price: 399,
    displayName: 'Starter',
  },
  lite: {
    stripeId: {
      test: 'plan_Emyn2XD4MTvsfU',
      live: 'plan_EnKPwLwiAkHmRf',
    },
    emailsLimit: 0.75,
    emailsLimitDisplayValue: '750k',
    price: 799,
    displayName: 'Lite',
  },
  pro: {
    stripeId: {
      test: 'plan_EmyoQ31wF1dT20',
      live: 'plan_EosG22KFXmP3fr',
    },
    emailsLimit: 2,
    emailsLimitDisplayValue: '2M',
    price: 1599,
    displayName: 'Pro',
  },
  enterprise: {
    stripeId: {
      test: 'plan_EosFJxgaRL49z3',
      live: 'plan_EosHVQLcDjtBZR',
    },
    emailsLimit: 10,
    emailsLimitDisplayValue: '10M',
    price: 4999,
    displayName: 'Enterprise',
  },
};
