export const SupportedApps = {
  beeApp: 'bee-app',
  sendybayOnboardingApp: 'onboarding-app',
  exitIntentApp: 'ey-app',
  speedWriteApp: 'sw-app',
  sidleApp: 'sd-app',
};

export const SupportedProducts = {
  SENDYBAY: 1,
  EMAILYARD: 2,
  SPEED_WRITE: 3,
  SIDLE: 4,
};
