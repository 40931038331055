import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { plans } from '../ControlPanelApp/Components/Onboarding/plans';
import pMinDelay from 'p-min-delay';
import { SupportedApps, SupportedProducts } from './constants';
import { ProductContext } from './ProductContext';
import { renderForTest } from '../../tests/testUtils';

export const Loadable = (factory) => () => {
  const Component = lazy(factory.loader);

  return (
    <Suspense fallback={<p>...</p>}>
      <Component />
    </Suspense>
  );
};

const LoadableControlPanelApp = Loadable({
  loader: () =>
    pMinDelay(
      import(/* webpackChunkName: "ControlPanelApp" */ 'ControlPanelApp/ControlPanelApp'),
      2,
    ),
  loading: () => <p></p>,
});

const LoadableBeeApp = Loadable({
  loader: () => pMinDelay(import(/* webpackChunkName: "BeeApp" */ '../BeeApp/BeeApp'), 200),
  loading: () => <p></p>,
});

const LoadableExitIntentApp = Loadable({
  loader: () =>
    pMinDelay(import(/* webpackChunkName: "ExitIntentApp" */ '../ExitIntentApp/ExitIntent'), 2),
  loading: () => <p></p>,
});

const LoadableSpeedWriteApp = Loadable({
  loader: () => import(/* webpackChunkName: "CrisperRouter" */ 'CrisperAi/CrisperRouter'),
  loading: () => <p></p>,
});

const mountingElements = {
  controlPanelAppElem: document.getElementById('control-panel-app'),
  beeAppElem: document.getElementById('bee-app'),
  exitIntentAppElem: document.getElementById('exitintent-app'),
  speedWriteAppElem: document.getElementById('sw-app'),
};

export const isEmailyard = () => {
  const appToBoot = getAppToBoot();
  if (appToBoot === SupportedApps.exitIntentApp) {
    return true;
  }
  return false;
};

export const isSidle = () => {
  const appToBoot = getAppToBoot();
  if (appToBoot === SupportedApps.sidleApp) {
    return true;
  }
  return false;
};

export const getAppToBoot = () => {
  // Figure out app from hostname
  const hostname = window.location.hostname;
  if (hostname.match('speedwrite') || hostname.match('crisper')) {
    return SupportedApps.speedWriteApp;
  } else if (hostname.match('sidle')) {
    return SupportedApps.sidleApp;
  } else if (hostname.match('emailyard')) {
    return SupportedApps.exitIntentApp;
  } else if (hostname === 'app.sendybay.com' || hostname.match('sendybay.netlify')) {
    return SupportedApps.sendybayOnboardingApp;
  }

  // Figure out app from localstorage
  const localBootAppVal = window.localStorage.getItem('SB_BOOT_APP');
  if (localBootAppVal !== null && Object.values(SupportedApps).includes(localBootAppVal)) {
    return localBootAppVal;
  }
  return SupportedApps.sendybayOnboardingApp;
};

export const setAppToBoot = (appName) => {
  if (!Object.values(SupportedApps).includes(appName)) {
    throw Error(`Cannot boot app ${appName}`);
  }
  window.localStorage.setItem('SB_BOOT_APP', appName);
};

export const mountOnboarding = (defaultPlanIndex = 0) => {
  const defaultPlanName = Object.keys(plans)[defaultPlanIndex];
  return renderOnboardingApp(defaultPlanName);
};

export const mountOnboardingWithPlan = (planName) => {
  return renderOnboardingApp(planName);
};

export const renderOnboardingApp = () => {
  return createRoot(mountingElements.controlPanelAppElem).render(<LoadableControlPanelApp />);
};

export const unmountReactApp = (elem) => {
  ReactDOM.unmountComponentAtNode(elem);
};

const renderBeeApp = () => {
  mountingElements.beeAppElem = document.getElementById('bee-app');
  const toRender = <LoadableBeeApp />;
  if (import.meta.env.VITEST) {
    return renderForTest(toRender);
  }
  return createRoot(mountingElements.beeAppElem).render(toRender);
};

const renderSpeedWriteApp = () => {
  return createRoot(mountingElements.speedWriteAppElem).render(<LoadableSpeedWriteApp />);
};

const renderExitIntentApp = ({ productCode = SupportedProducts.EMAILYARD, target = null }) => {
  const productConfig = {
    [SupportedProducts.EMAILYARD]: {
      name: 'Emailyard',
      supportEmail: 'support@emailyard.com',
      squareLogoUrl: 'https://sendybay-uploads.s3.amazonaws.com/public-files/ey-old-square.png',
      squareLogoUrl2x:
        'https://sendybay-uploads.s3.amazonaws.com/public-files/ey-old-square-2x.png',
    },
    [SupportedProducts.SIDLE]: {
      name: 'Sidle',
      supportEmail: 'support@sidle.co',
      squareLogoUrl: 'https://ey-cdn.s3.amazonaws.com/sidle-logo.png',
      squareLogoUrl2x: 'https://ey-cdn.s3.amazonaws.com/sidle-logo.png',
    },
  };
  const toRender = (
    <ProductContext.Provider value={{ productConfig: productConfig[productCode] }}>
      <LoadableExitIntentApp />
    </ProductContext.Provider>
  );

  if (import.meta.env.VITEST) {
    return renderForTest(toRender);
  }

  return createRoot(target || mountingElements.exitIntentAppElem).render(toRender);
};

export const mountEmailyard = ({ target = null } = {}) => {
  return renderExitIntentApp({ target });
};

export const mountSidle = () => {
  mountingElements.exitIntentAppElem = document.getElementById('exitintent-app');
  return renderExitIntentApp({ productCode: SupportedProducts.SIDLE });
};

export const mountBee = () => {
  return renderBeeApp();
};

export const mountSpeedWriteApp = () => {
  mountingElements.speedWriteAppElem = document.getElementById('sw-app');
  return renderSpeedWriteApp();
};
